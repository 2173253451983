import React, { useCallback, useEffect, useState } from 'react';
// STYLES
import { ContentTypeFgts, NumberProposalfgts, Paginator, SelectLoanFgts, TitleStep } from '../styles';

// COMPONENTS
import { Col, Row } from '../../../components/GRID';
import { Input } from '../../../components/UI/Input';
import { MaskedInput } from '../../../components/UI/MaskedInput';
import { Select } from '../../../components/UI/Select';
import { TodoLoan } from '../../../components/UI/TodoLoan';

// ULTILS
import { createLoanTypeOptions } from '../../../utils/createLoanTypeOptions';
import { hasError, hasFilled } from '../../../utils/fieldsValidation';

// ENUMS
import { LoanType, Type } from '../../../enums/loan-type.enum';

// CONTEXT
import { useProposal } from '../../../hooks/AppContext';

// SERVICES
import ProposalService from '../../../services/ProposalService';

// INTERFACE
import Proposal from '../../../interfaces/Proposal';

const optionsLoan = createLoanTypeOptions([LoanType.NEW_LOAN]);

interface IForm {
  formValues: Proposal;
  onChange: (e: any) => void;
}

export const Form = ({ onChange, formValues }: IForm) => {
  const { setNextStepDisabled, cnpjconsignees, setCnpjconsignees, consignCardPendence } = useProposal();

  const [validationError, setValidationError] = useState(hasError(1));

  const defaultValueConvenant = cnpjconsignees.find((val: any) => val.value == formValues?.cnpjConsigneeId);
  const defaultValueConvenantFgts = cnpjconsignees.find(
    (val: any) => val.label == 'Antecipação saque-aniversário FGTS',
  );

  const [loan, setLoan] = useState<string>('');
  const [proposalNumber, setProposalNumber] = useState<string>('');
  const [purchaseMargin, setPurchaseMargin] = useState<number>(0);

  const handleChangeLoan = useCallback(
    (e) => {
      onChange({
        value: e.value,
        name: 'loanType',
      });
      setLoan(e.value);
    },
    [onChange, formValues],
  );

  const handleChangeProposalNumber = useCallback(
    (e) => {
      onChange({
        value: e.value,
        name: 'proposalNumber',
      });
      setProposalNumber(e.value);
    },
    [onChange, formValues],
  );

  const handleChangePurchaseMargin = useCallback(
    (e) => {
      onChange({
        value: e.value,
        name: 'purchaseMargin',
      });
      setPurchaseMargin(e.value);
    },
    [onChange, formValues],
  );

  const handleReturnError = useCallback(
    (name: string, err: string) => {
      setValidationError({
        ...validationError,
        [name]: err,
      });
    },
    [validationError],
  );

  const handleChange = useCallback(
    (e: any) => {
      onChange(e);
    },
    [onChange],
  );

  const handleChangeCnpjConsigneeId = useCallback(
    (field: any) => {
      handleChangeLoan({
        value: '',
      });
      handleChangeProposalNumber({ value: '' });
      handleChangePurchaseMargin({ value: 0 });
      onChange(field);
    },
    [formValues],
  );

  /* LOAN TYPE PROPOSAL  FGTS*/
  const handleChangeProposal = useCallback(
    (e: any) => {
      formValues.loanDetails = [{ proposalNumber: e.value, loanType: LoanType.NEW_LOAN }];
      onChange(e);
    },
    [onChange, formValues.loanDetails],
  );

  // VALIDATION ERROR FORM
  useEffect(() => {
    if (formValues.type === Type.FGTS) {
      formValues.cnpjConsigneeId = defaultValueConvenantFgts.value;
      const invalid =
        hasFilled(2, formValues) ||
        formValues.loanDetails[0]?.proposalNumber === '' ||
        !(Object.values(validationError).find((el) => !!el) === undefined);
      setNextStepDisabled(invalid);
    } else {
      const invalid = hasFilled(2, formValues) || !(Object.values(validationError).find((el) => !!el) === undefined);
      setNextStepDisabled(invalid);
    }
  }, [validationError, formValues]);

  useEffect(() => {
    // TODO REFATORAÇÂO
    const fetchOptionsCnpjconsignees = async () => {
      if (formValues.type === Type.CONSIGNED) {
        const fetchOptionsCnpjconsignees = await ProposalService.fetCnpjconsignees();
        const { data } = fetchOptionsCnpjconsignees;

        const options = data
          .filter((item: any) => item.active)
          .map((item: any) => ({
            value: item.id,
            label: item.description,
            name: 'cnpjConsigneeId',
            hasConsignCard: item.hasConsignCard,
            limitMultiply: item.limitMultiply,
          }));
        setCnpjconsignees(options);
      } else {
        const fetchOptionsCnpjconsigneesFgts = await ProposalService.fetCnpjconsigneesFgts();
        const { data } = fetchOptionsCnpjconsigneesFgts;

        const options = data
          .filter((item: any) => item.active)
          .map((item: any) => ({
            value: item.id,
            label: item.description,
            name: 'cnpjConsigneeId',
            hasConsignCard: item.hasConsignCard,
            limitMultiply: item.limitMultiply,
          }));
        setCnpjconsignees(options);
      }
    };

    fetchOptionsCnpjconsignees();
  }, [formValues.type]);

  return (
    <Row>
      <Col sm={18} md={18} lg={18}>
        <Paginator>2/5</Paginator>
        <TitleStep>Fale um pouco mais sobre quem está solicitando o empréstimo</TitleStep>
        <Row>
          <Col sm={18} md={12} lg={12}>
            <div style={{ flexDirection: 'column' }}>
              <div>
                <Input
                  id="name"
                  name="name"
                  label="Nome completo"
                  type="text"
                  value={formValues.name || ''}
                  onChange={handleChange}
                  placeholder="Nome Completo"
                  hasError={handleReturnError}
                  disabled={consignCardPendence}
                />
              </div>
              <div style={{ alignItems: 'flex-end' }}>
                <MaskedInput
                  type="text"
                  id="documentNumber"
                  name="documentNumber"
                  label="CPF"
                  value={formValues.documentNumber || ''}
                  onChange={handleChange}
                  mask={'999.999.999-99'}
                  placeholder={'000.000.000-00'}
                  hasError={handleReturnError}
                  isDisabled={consignCardPendence}
                />
                <MaskedInput
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Celular"
                  value={formValues.phoneNumber || ''}
                  type="text"
                  mask="(99) 99999-9999"
                  placeholder="(00) 00000 0000"
                  onChange={handleChange}
                  hasError={handleReturnError}
                  isDisabled={consignCardPendence}
                />
              </div>

              {/* LOAN TYPE CNPJ CONSIGNEEID*/}
              {formValues.type === Type.CONSIGNED ? (
                <Select
                  id="cnpjConsigneeId"
                  name="cnpjConsigneeId"
                  label="Convênio"
                  placeholder="Selecione um convênio"
                  options={cnpjconsignees}
                  defaultValue={defaultValueConvenant}
                  onChange={handleChangeCnpjConsigneeId}
                  isDisabled={formValues.loanDetails.length !== 0 && defaultValueConvenant !== undefined}
                />
              ) : (
                <Select
                  id="cnpjConsigneeId"
                  name="cnpjConsigneeId"
                  label="Convênio"
                  placeholder="Selecione um convênio"
                  options={cnpjconsignees}
                  defaultValue={defaultValueConvenantFgts}
                  onChange={handleChangeCnpjConsigneeId}
                  isDisabled={formValues.type === Type.FGTS}
                />
              )}
            </div>
            {/* LOAN TYPE PROPOSAL */}
            {formValues.type === Type.CONSIGNED ? (
              <div className="loanList">
                <TodoLoan
                  onChange={onChange}
                  formValues={formValues}
                  loan={loan}
                  proposalNumber={proposalNumber}
                  purchaseMargin={purchaseMargin}
                  handleChangeLoan={handleChangeLoan}
                  handleChangeProposalNumber={handleChangeProposalNumber}
                  handleChangePurchaseMargin={handleChangePurchaseMargin}
                />
              </div>
            ) : (
              <ContentTypeFgts>
                <p>Insira as informações da proposta Sicred</p>
                <div>
                  <SelectLoanFgts>
                    <Select
                      id="loanType"
                      name="loanType"
                      label="Tipo de empréstimo"
                      placeholder="Selecione uma opção"
                      options={optionsLoan}
                      onChange={undefined}
                      value={optionsLoan.filter(({ value }) => value == LoanType.NEW_LOAN)}
                      isDisabled={formValues.type === Type.FGTS}
                    />
                  </SelectLoanFgts>
                  <NumberProposalfgts>
                    <MaskedInput
                      id="proposalNumber"
                      name="proposalNumber"
                      label="Número da proposta"
                      type="text"
                      onChange={handleChangeProposal}
                      placeholder="0000000"
                      mask="9999999"
                      isDisabled={formValues.type === Type.FGTS}
                      value={formValues.loanDetails[0]?.proposalNumber || ''}
                      hasError={handleReturnError}
                    />
                  </NumberProposalfgts>
                </div>
              </ContentTypeFgts>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
